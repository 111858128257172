import React from 'react';
import useAuth from 'hooks/useAuth';
import ClientView from './ClientView';
import ImporterView from './ImporterView';

const TrackingIndex = () => {
  const { auth } = useAuth();

  const isClient = auth?.groups?.some(group => group.includes('Client'));
  const isAdminOrStaff = auth?.groups?.some(group =>
    group === 'Administrator' || group === 'Staff'
  );

  if (!auth || !auth.groups) {
    return <div>Loading...</div>;
  }

  if (isClient) {
    return <ClientView />;
  }

  if (isAdminOrStaff) {
    return <ImporterView />;
  }

  return <div>Access Denied</div>;
};

export default TrackingIndex;