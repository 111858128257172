import React from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from 'dayjs';
import { useAnalytics } from '../../context/analyticsContext';

const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    // last month
    {
        label: 'Last Month',
        getValue: () => {
            const today = dayjs();
            const prevMonth = today.subtract(1, 'month');
            return [prevMonth.startOf('month'), prevMonth.endOf('month')];
        }
    },
    { label: 'Reset', getValue: () => [null, null] },
];


const DateRangeFilter = () => {
    const {
        tempEtdStartDate,
        tempEtdEndDate,
        setTempEtdStartDate,
        setTempEtdEndDate
    } = useAnalytics();

    const handleDateRangeChange = (newValue) => {
        if (Array.isArray(newValue)) {
            setTempEtdStartDate(newValue[0]);
            setTempEtdEndDate(newValue[1]);
        }
    };

    return (
        <FormControl sx={{ width: 300, mb: 3, ml: 2 }}>
            <Typography variant="subtitle2" mb={1}>ETD Date Range</Typography>
            <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                name="allowedRange"
                value={[tempEtdStartDate, tempEtdEndDate]}
                onChange={handleDateRangeChange}
                slotProps={{
                    shortcuts: {
                        items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                    textField: {
                        InputProps: {
                            placeholder: "Select date range"
                        }
                    }
                }}
            />
        </FormControl>
    );
};

export default React.memo(DateRangeFilter);