import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import {
    DataGridPremium,
    useGridApiContext, gridColumnVisibilityModelSelector
} from '@mui/x-data-grid-premium';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatCompactCurrency as formatCurrency } from '../../../../../../../../utils/formatters';
import DimensionalAnalysisViewControls from './DimensionalAnalysisViewControls';
import { useAnalytics } from '../../context/analyticsContext';

const ColumnGroupRoot = styled('div')({
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
});

const ColumnGroupTitle = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const formatMonthName = (monthKey) => {
    const [year, month] = monthKey.split('-');
    const date = new Date(year, parseInt(month) - 1);
    return date.toLocaleString('default', { month: 'short', year: '2-digit' });
};

function CollapsibleHeaderGroup({ groupId, headerName }) {
    const apiRef = useGridApiContext();
    const columnVisibilityModel = gridColumnVisibilityModelSelector(apiRef);

    if (!groupId) return null;

    // Update fields array to include all possible columns
    const fields = [
        `${groupId}-invoice`,
        `${groupId}-product_purchase_cost`,
        `${groupId}-margin`,
        `${groupId}-margin_percentage`,
        `${groupId}-avg_unit_product_invoice`,
        `${groupId}-avg_unit_product_purchase_cost`
    ];

    const isGroupCollapsed = fields.every((field) => columnVisibilityModel[field] === false);

    return (
        <ColumnGroupRoot>
            <ColumnGroupTitle>{headerName}</ColumnGroupTitle>
            <IconButton
                size="small"
                sx={{ ml: 0.5 }}
                onClick={() => {
                    const newModel = { ...columnVisibilityModel };
                    fields.forEach((field) => {
                        newModel[field] = !!isGroupCollapsed;
                    });
                    apiRef.current.setColumnVisibilityModel(newModel);
                }}
            >
                {isGroupCollapsed ? (
                    <KeyboardArrowRightIcon fontSize="small" />
                ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                )}
            </IconButton>
        </ColumnGroupRoot>
    );
}


const DIMENSION_LABELS = {
    buyer: 'Client',
    product: 'Product',
    category: 'Category',
    importer: 'Importer'
};

const formatDimensionHeader = (dimension) => {
    return DIMENSION_LABELS[dimension] || dimension;
};

const getDimensionValue = (row, dimension) => {
    return row?.dimensions?.[dimension] || 'Unknown';
};

const DimensionalAnalysisGrid = ({ data, dimensions = ['buyer'] }) => {
    const { hiddenColumns } = useAnalytics();
    const [gridKey, setGridKey] = useState(0);

    if (!data || !dimensions.length) {
        return (
            <Card>
                <Box p={2}>
                    <Typography variant="h6" gutterBottom>Analysis</Typography>
                    <Typography>Please select at least one dimension to analyze.</Typography>
                </Box>
            </Card>
        );
    }

    const rows = Array.isArray(data) ? data : [data];

    // Create dimension columns dynamically
    const dimensionColumns = [...new Set(dimensions)].map(dimension => ({
        field: `dimension_${dimension}`,
        headerName: formatDimensionHeader(dimension),
        flex: 1,
        minWidth: 200,
        valueGetter: (value, row) => getDimensionValue(row, dimension)
    }));

    const baseColumns = dimensionColumns;

    const totalColumns = [
        ...baseColumns,
        {
            field: 'total_invoice',
            headerName: 'Invoice',
            flex: 1,
            minWidth: 90,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: 'total_product_purchase_cost',
            headerName: 'Purchase',
            flex: 1,
            minWidth: 90,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: 'total_margin',
            headerName: 'Margin',
            flex: 1,
            minWidth: 90,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: 'total_margin_percentage',
            headerName: 'Margin %',
            flex: 1,
            minWidth: 90,
            valueFormatter: (value) => `${value.toFixed(2)}%`
        }
    ];

    // Collect all unique months from all rows
    const getAllMonths = (rows) => {
        const monthsSet = new Set();
        rows.forEach(row => {
            if (row.months) {
                Object.keys(row.months).forEach(month => monthsSet.add(month));
            }
        });
        return Array.from(monthsSet).sort((a, b) => b.localeCompare(a));
    };

    const allMonths = getAllMonths(rows);

    const monthlyColumns = allMonths.flatMap(month => [
        {
            field: `${month}-invoice`,
            headerName: 'Invoice',
            width: 100,
            valueGetter: (value, row) => row?.months?.[month]?.invoice ?? 0,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: `${month}-product_purchase_cost`,
            headerName: 'Purchase',
            width: 100,
            valueGetter: (value, row) => row?.months?.[month]?.product_purchase_cost ?? 0,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: `${month}-margin`,
            headerName: 'Margin',
            width: 100,
            valueGetter: (value, row) => row?.months?.[month]?.margin ?? 0,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: `${month}-margin_percentage`,
            headerName: 'Margin %',
            width: 100,
            valueGetter: (value, row) => row?.months?.[month]?.margin_percentage ?? 0,
            valueFormatter: (value) => value ? `${value.toFixed(2)}%` : '0%',
        },
        {
            field: `${month}-avg_unit_product_invoice`,
            headerName: 'Avg Unit Invoice',
            width: 100,
            valueGetter: (value, row) => row?.months?.[month]?.avg_unit_product_invoice ?? 0,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        },
        {
            field: `${month}-avg_unit_product_purchase_cost`,
            headerName: 'Avg Unit Purchase',
            width: 100,
            valueGetter: (value, row) => row?.months?.[month]?.avg_unit_product_purchase_cost ?? 0,
            valueFormatter: (value) => value ? formatCurrency(value) : '$0',
        }
    ]);

    const columns = [
        ...totalColumns.map(col => ({
            ...col,
            width: 150,
        })),
        ...monthlyColumns
    ];

    // disable gruppable, aggregable for all columns
    columns.forEach((column) => {
        column.editable = false;
        column.groupable = false;
        column.aggregable = false;
    });

    const isColumnVisible = (field) => {
        // Handle total columns
        console.log(field);
        if (field.startsWith('total_')) {
            const columnType = field.replace('total_', '');
            return !hiddenColumns.includes(columnType);
        }

        // Handle monthly columns
        if (field.includes('-')) {
            const columnType = field.split('-')[2];
            return !hiddenColumns.includes(columnType);
        }

        // Always show dimension columns
        if (field.startsWith('dimension_')) {
            return true;
        }

        return true;
    };

    const filteredColumns = columns.filter(col => isColumnVisible(col.field));


    const columnGroupingModel = [
        {
            groupId: 'totals',
            headerName: 'Totals',
            children: totalColumns.map(col => ({ field: col.field })),
        },
        ...allMonths.map(month => ({
            groupId: month,
            headerName: formatMonthName(month),
            renderHeaderGroup: (params) => <CollapsibleHeaderGroup {...params} />,
            children: [
                { field: `${month}-invoice` },
                { field: `${month}-product_purchase_cost` },
                { field: `${month}-margin` },
                { field: `${month}-margin_percentage` },
                { field: `${month}-avg_unit_product_invoice` },
                { field: `${month}-avg_unit_product_purchase_cost` }
            ]
        }))
    ];


    const filteredColumnGroupingModel = columnGroupingModel.map(group => ({
        ...group,
        children: group.children.filter(child => isColumnVisible(child.field))
    }));

    // Generate a unique ID for each row based on all dimensions
    const getRowId = (row) => {
        return dimensions
            .map(dim => getDimensionValue(row, dim))
            .join('_');
    };

    return (
        <Card>
            <DimensionalAnalysisViewControls />
            <Box p={1}>

                <DataGridPremium
                    key={gridKey} // Add this line
                    rows={rows}
                    columns={filteredColumns}
                    columnGroupingModel={filteredColumnGroupingModel}
                    getRowId={getRowId}
                    autoHeight
                    disableRowSelectionOnClick
                    experimentalFeatures={{ columnGrouping: true }}
                    pageSizeOptions={[20, 50, 100]}
                    pagination
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    columnHeaderHeight={36}
                />
            </Box>
        </Card>
    );
};

export default DimensionalAnalysisGrid;