import React from 'react';
import Grid from '@mui/material/Grid';
import AnalyticsTabs from './AnalyticsTabs';
import MarginAnalysisGrid from './dimensionalAnalytics/MarginAnalysisGrid';
import DimensionalAnalysisWrapper from './dimensionalAnalytics/DimensionalAnalysisWrapper';
import { DIMENSION_TABS } from '../constants';
import { useAnalytics } from '../context/analyticsContext';

const AnalyticsContent = React.memo(({
    formattedData,
    selectedStatuses,
    selectedBuyer,
    etdStartDate,
    etdEndDate
}) => {
    const {
        activeTab,
        setActiveTab
    } = useAnalytics();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const dimensionalProps = React.useMemo(() => ({
        activeTab,
        selectedStatuses,
        selectedBuyer,
        etdStartDate,
        etdEndDate,
    }), [activeTab, selectedStatuses, selectedBuyer, etdStartDate, etdEndDate]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AnalyticsTabs
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                />
                {activeTab === DIMENSION_TABS.MONTHLY ? (
                    <MarginAnalysisGrid data={formattedData} />
                ) : (
                    <DimensionalAnalysisWrapper {...dimensionalProps} />
                )}
            </Grid>
        </Grid>
    );
});

export default AnalyticsContent;