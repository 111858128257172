import React, { useState } from 'react';
import { useFetchData } from 'services/queries';
import DimensionSelector from './DimensionSelector';
import DimensionalAnalysisGrid from './DimensionalAnalysisGrid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import { DIMENSION_TABS, DIMENSION_CONFIG } from '../../constants';
import { getQueryParams } from '../../utils/queryUtils';
import { useAnalytics } from '../../context/analyticsContext';

const DimensionalSkeleton = () => (
    <Card>
        <Box p={2}>
            <Skeleton width={200} height={32} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" height={400} />
        </Box>
    </Card>
);

const DimensionalAnalysisWrapper = () => {
    const {
        activeTab,
        selectedStatuses,
        selectedBuyer,
        selectedImporter,
        etdStartDate,
        etdEndDate
    } = useAnalytics();

    const [customDimensions, setCustomDimensions] = useState([]);

    const { data: dimensionalData, isLoading } = useFetchData(
        `order/sales-order/financial-metrics-by-dimension${getQueryParams({
            selectedStatuses,
            selectedBuyer,
            selectedImporter,
            etdStartDate,
            etdEndDate,
            activeTab,
            customDimensions
        })}`,
        true
    );

    const handleCustomDimensionsChange = (newDimensions) => {
        if (newDimensions.length > 0) {
            setCustomDimensions(newDimensions);
        }
    };

    if (isLoading) {
        return <DimensionalSkeleton />;
    }

    return (
        <>
            {activeTab === DIMENSION_TABS.CUSTOM && (
                <DimensionSelector
                    availableDimensions={DIMENSION_CONFIG}
                    selectedDimensions={customDimensions}
                    onChange={handleCustomDimensionsChange}
                />
            )}
            {(activeTab !== DIMENSION_TABS.CUSTOM || customDimensions.length > 0) && (
                <DimensionalAnalysisGrid
                    data={dimensionalData}
                    dimensions={activeTab === DIMENSION_TABS.CUSTOM
                        ? customDimensions.map(tab => DIMENSION_CONFIG[tab].dimension)
                        : [DIMENSION_CONFIG[activeTab].dimension]
                    }
                />
            )}
        </>
    );
};

export default React.memo(DimensionalAnalysisWrapper);