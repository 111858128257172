import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useFetchData } from 'services/queries';
import CumulativePerformanceChart from './analytics/components/charts/CumulativePerformanceChart';
import MonthlyRevenueChart from './analytics/components/charts/MonthlyRevenueChart';
import FilterModal from './analytics/components/filters/FilterModal';
import AnalyticsContent from './analytics/components/AnalyticsContent';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getQueryParams } from './analytics/utils/queryUtils';
import { AnalyticsProvider, useAnalytics } from './analytics/context/analyticsContext';


const EXCLUDED_INITIAL_STATUSES = ['Open', 'Ready for Processing', 'Discontinued', 'Awaiting Approval'];

const AnalyticsSkeleton = () => (
    <Box pb={1}>
        <Stack direction="row" spacing={2} mb={3}>
            <Skeleton width={200} height={56} />
            <Skeleton width={200} height={56} />
        </Stack>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton width={500} height={48} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" height={400} />
            </Grid>
        </Grid>
    </Box>
);

const MemoizedCharts = React.memo(({ data }) => (
    <Grid container spacing={3} p={2}>
        <Grid item xs={12} md={6}>
            <CumulativePerformanceChart data={data} />
        </Grid>
        <Grid item xs={12} md={6}>
            <MonthlyRevenueChart data={data} />
        </Grid>
    </Grid>
));

const AnalyticsMain = ({ currentTab }) => {
    const {
        selectedStatuses,
        setSelectedStatuses,
        selectedBuyer,
        selectedImporter,
        etdStartDate,
        etdEndDate,
        activeTab,
        resetTempFilters
    } = useAnalytics();

    const [monthlyMetrics, setMonthlyMetrics] = useState([]);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const { data: statusList } = useFetchData('order/status-sales-order/', true);

    useEffect(() => {
        if (statusList) {
            const initialStatuses = statusList
                .filter(status => !EXCLUDED_INITIAL_STATUSES.includes(status.status))
                .map(status => status.id);
            setSelectedStatuses(initialStatuses);
        }
    }, [statusList]);

    const { data, error, isLoading } = useFetchData(
        `order/sales-order/monthly-financial-metrics${getQueryParams({
            selectedStatuses,
            selectedBuyer,
            selectedImporter,
            etdStartDate,
            etdEndDate,
            activeTab
        })}`,
        true,
        currentTab === 2
    );

    useEffect(() => {
        if (data) {
            setMonthlyMetrics(data);
        }
    }, [data]);

    const formattedData = React.useMemo(() => {
        return monthlyMetrics.map(item => ({
            ...item,
            monthYear: `${new Date(item.year, item.month - 1).toLocaleString('default', { month: 'short' })} ${item.year}`
        }));
    }, [monthlyMetrics]);

    const handleOpenFilters = () => {
        resetTempFilters();
        setIsFilterModalOpen(true);
    };

    if (isLoading) return <AnalyticsSkeleton />;
    if (error) return <div>Error!</div>;

    return (
        <Box pb={1}>
            <Box mb={2}>
                <Button
                    variant="outlined"
                    startIcon={<FilterListIcon />}
                    onClick={handleOpenFilters}
                >
                    Manage Filters
                </Button>
            </Box>

            <FilterModal
                open={isFilterModalOpen}
                onClose={() => setIsFilterModalOpen(false)}
            />

            <MemoizedCharts data={formattedData} />
            <AnalyticsContent formattedData={formattedData} />
        </Box>
    );
};

const Analytics = ({ currentTab }) => {
    return (
        <AnalyticsProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AnalyticsMain currentTab={currentTab} />
            </LocalizationProvider>
        </AnalyticsProvider>
    );
};

export default React.memo(Analytics);