import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StatusFilter from './StatusFilter';
import BuyerFilter from './BuyerFilter';
import DateRangeFilter from './DateRangeFilter';
import ImporterFilter from './ImporterFilter';
import { useAnalytics } from '../../context/analyticsContext';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const FilterModal = ({ open, onClose }) => {
    const { applyFilters, resetTempFilters } = useAnalytics();

    const handleClose = () => {
        resetTempFilters();
        onClose();
    };

    const handleApply = () => {
        applyFilters();
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Filter Options</Typography>
                    <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Stack spacing={3}>
                    <DateRangeFilter label="ETD Date Range" />
                    <StatusFilter />
                    <BuyerFilter />
                    <ImporterFilter />
                </Stack>

                <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleApply} variant="contained">
                        Apply Filters
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default React.memo(FilterModal);