import { DIMENSION_TABS, DIMENSION_CONFIG } from '../constants';

export const getQueryParams = ({
    selectedStatuses,
    selectedBuyer,
    selectedImporter,
    etdStartDate,
    etdEndDate,
    activeTab,
    customDimensions
}) => {
    const params = [];

    if (selectedStatuses?.length) {
        const statusParam = selectedStatuses.length > 1 ? 'status__in' : 'status';
        params.push(`${statusParam}=${selectedStatuses.join(',')}`);
    }

    if (selectedBuyer) {
        params.push(`buyer=${selectedBuyer}`);
    }

    if (selectedImporter) {
        params.push(`delivery_info__importer=${selectedImporter}`);
    }

    if (etdStartDate) {
        params.push(`delivery_info__etd__gte=${etdStartDate.format('YYYY-MM-DD')}`);
    }

    if (etdEndDate) {
        params.push(`delivery_info__etd__lte=${etdEndDate.format('YYYY-MM-DD')}`);
    }

    if (activeTab === DIMENSION_TABS.CUSTOM && customDimensions?.length) {
        const uniqueDimensions = [...new Set(customDimensions.map(tab => DIMENSION_CONFIG[tab]?.dimension))];
        params.push(`dimensions=${uniqueDimensions.join(',')}`);
    } else if (activeTab !== DIMENSION_TABS.MONTHLY) {
        params.push(`dimensions=${DIMENSION_CONFIG[activeTab]?.dimension}`);
    }

    return params.length ? `?${params.join('&')}` : '';
};