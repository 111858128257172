import React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAnalytics, ColumnGroups } from '../../context/analyticsContext';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const COLUMN_CONFIG = {
    [ColumnGroups.Invoice]: 'Invoice',
    [ColumnGroups.Purchase]: 'Purchase Cost',
    [ColumnGroups.Margin]: 'Margin',
    [ColumnGroups.MarginPercentage]: 'Margin %',
    [ColumnGroups.AvgUnitInvoice]: 'Avg Unit Invoice',
    [ColumnGroups.AvgUnitPurchase]: 'Avg Unit Purchase',
};

const DimensionalAnalysisViewControls = () => {
    const { hiddenColumns, toggleColumn } = useAnalytics();

    return (
        <Box p={1}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    boxShadow: 'none',
                    border: 'none',
                }}
                component="ul"
            >
                <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                        mr: 1,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Selected metrics:
                </Typography>

                {Object.entries(COLUMN_CONFIG).map(([column, label]) => (
                    <ListItem key={column}>
                        <Chip
                            icon={hiddenColumns.includes(column) && <VisibilityOff />}
                            label={label}
                            color={hiddenColumns.includes(column) ? 'default' : 'primary'}
                            onClick={() => toggleColumn(column)}
                            variant={hiddenColumns.includes(column) ? 'outlined' : 'filled'}
                            sx={{
                                '&:hover': {
                                    backgroundColor: hiddenColumns.includes(column)
                                        ? 'action.hover'
                                        : 'primary.dark',
                                },
                                transition: 'all 0.2s',
                                '& .MuiChip-icon': {
                                    fontSize: '1.2rem',
                                }
                            }}
                        />
                    </ListItem>
                ))}
            </Box>
        </Box>
    );
};

export default DimensionalAnalysisViewControls;