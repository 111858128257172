import { MdPending, MdCancel, MdPayments, MdLocalShipping, MdDone } from "react-icons/md";
import { TbPackage, TbTruck, TbClipboardCheck } from "react-icons/tb";
import { BsGearFill } from "react-icons/bs";

export const CATEGORY_CHOICES = [
    { value: 'IF', label: 'International Freight' },
    { value: 'DF', label: 'Destination Fee' },
    { value: 'CA', label: 'Company Audit' },
    { value: 'CI', label: 'Cargo Inspection' },
    { value: 'FA', label: 'Factory' },
    { value: 'OT', label: 'Others' },
];

export const SUPPLIER_TYPE_CHOICES = [
    { value: 'FACTORY', label: 'Factory' },
    { value: 'FORWARDER', label: 'Forwarder' },
    { value: 'COMPANY_AUDIT', label: 'Company Audit' },
];

export const STATUS_CONFIG = {
    'Awaiting Approval': {
        color: 'warning',
        icon: MdPending
    },
    'Discontinued': {
        color: 'error',
        icon: MdCancel
    },
    'Ready for Processing': {
        color: 'info',
        icon: null
    },
    'Open': {
        color: 'primary',
        icon: null
    },
    'Awaiting Initial Payment': {
        color: 'warning',
        icon: MdPayments
    },
    'Awaiting Packaging': {
        color: 'info',
        icon: TbPackage
    },
    'In Production': {
        color: 'secondary',
        icon: BsGearFill
    },
    'Awaiting Inspection': {
        color: 'warning',
        icon: TbClipboardCheck
    },
    'Awaiting Final Payment': {
        color: 'warning',
        icon: MdPayments
    },
    'Awaiting Shipment': {
        color: 'info',
        icon: MdLocalShipping
    },
    'In Transit': {
        color: 'info',
        icon: TbTruck
    },
    'Delivered': {
        color: 'success',
        icon: MdDone
    }
};

export const STATUS_GROUPS = {
    COMPLETED: ['Delivered', 'Discontinued'],
    IN_PROGRESS: Object.keys(STATUS_CONFIG).filter(
        status => !['Delivered', 'Discontinued'].includes(status)
    )
};