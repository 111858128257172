import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useFetchData } from 'services/queries';
import { useAnalytics } from '../../context/analyticsContext';

const StatusFilter = () => {
    const { tempSelectedStatuses, setTempSelectedStatuses } = useAnalytics();
    const { data: statusList, error: statusError, isLoading: statusLoading } = useFetchData('order/status-sales-order/', true);

    if (statusLoading) return <div>Loading...</div>;
    if (statusError) return <div>Error!</div>;

    return (
        <FormControl sx={{ width: 300, mb: 3 }}>
            <InputLabel>Status Filter</InputLabel>
            <Select
                multiple
                value={tempSelectedStatuses}
                onChange={(e) => setTempSelectedStatuses(e.target.value)}
                input={<OutlinedInput label="Status Filter" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip
                                key={value}
                                label={statusList?.find(status => status.id === value)?.status || value}
                                size="small"
                            />
                        ))}
                    </Box>
                )}
            >
                {statusList?.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                        {status.status}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default StatusFilter;