
export const formatCompactCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short'
    }).format(value);
};

export const formatDetailedCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
}


export const formatPercentage = (value) => {
    return `${value.toFixed(2)}%`;
};

export const formatMonth = (monthNumber) => {
    return new Date(2000, monthNumber - 1).toLocaleString('default', { month: 'short' });
};