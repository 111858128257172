import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

const DimensionSelector = ({ availableDimensions, selectedDimensions, onChange }) => {
    const handleChange = (event) => {
        // Remove duplicates and ensure at least one dimension
        const uniqueValues = [...new Set(event.target.value)];
        if (uniqueValues.length > 0) {
            onChange(uniqueValues);
        }
    };

    return (
        <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
                <InputLabel>Select Dimensions</InputLabel>
                <Select
                    multiple
                    value={selectedDimensions}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Stack direction="row" spacing={0.5}>
                            {selected.map((value) => (
                                <Chip
                                    key={value}
                                    label={availableDimensions[value].label.replace('By ', '')}
                                    size="small"
                                />
                            ))}
                        </Stack>
                    )}
                >
                    {Object.entries(availableDimensions).map(([key, { label }]) => (
                        <MenuItem key={key} value={key}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default DimensionSelector;