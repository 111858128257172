import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const AnalyticsTabs = React.memo(({ activeTab, onTabChange }) => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={onTabChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label="By Month" />
            <Tab label="By Client" />
            <Tab label="By Product" />
            <Tab label="By Category" />
            <Tab label="By Importer" />
            <Tab label="Custom" />
        </Tabs>
    </Box>
));

export default AnalyticsTabs;