import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useFetchData } from 'services/queries';
import { useAnalytics } from '../../context/analyticsContext';

const ImporterFilter = () => {
    const { tempSelectedImporter, setTempSelectedImporter } = useAnalytics();
    const { data: importerList, error: importerError, isLoading: importerLoading } = useFetchData('client/importer', true);

    if (importerLoading) return <div>Loading...</div>;
    if (importerError) return <div>Error!</div>;

    const selectedImporterObject = importerList?.find(importer => importer.id === tempSelectedImporter) || null;

    return (
        <FormControl sx={{ width: 300, mb: 3, ml: 2 }}>
            <Autocomplete
                value={selectedImporterObject}
                onChange={(_, newValue) => setTempSelectedImporter(newValue?.id || '')}
                options={importerList || []}
                getOptionLabel={(option) => option.social_reason || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Importer Filter"
                        placeholder="Select an importer"
                    />
                )}
                loading={importerLoading}
                loadingText="Loading importers..."
                noOptionsText="No importers found"
                clearText="Clear"
            />
        </FormControl>
    );
};

export default ImporterFilter;