
export const DIMENSION_TABS = {
    MONTHLY: 0,
    CLIENT: 1,
    PRODUCT: 2,
    CATEGORY: 3,
    IMPORTER: 4,
    CUSTOM: 5
};

export const DIMENSION_CONFIG = {
    [DIMENSION_TABS.CLIENT]: { label: 'By Client', dimension: 'buyer' },
    [DIMENSION_TABS.PRODUCT]: { label: 'By Product', dimension: 'product' },
    [DIMENSION_TABS.CATEGORY]: { label: 'By Category', dimension: 'category' },
    [DIMENSION_TABS.IMPORTER]: { label: 'By Importer', dimension: 'importer' }
};